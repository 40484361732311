import * as v from 'valibot';
import type {
    BaseValidation,
    InferOutput,
    BaseIssue,
    ErrorMessage
} from 'valibot';
import { stdDateFormat, dtIsPast, fmtDate } from '~/utils';
import type {
    OrderTotalsComputedItem
} from '~/types';
import { fieldModelErrors as fErr } from '~/constants';

export const OrderTotalsModel: OrderTotalsComputedItem = {
    qty: 0,
    total: 0
};

export const bulkOrderModelDates = {
    minOrderDateStr: fmtDate(new Date(), stdDateFormat),
    todayOrderDateStr: fmtDate(new Date(), stdDateFormat)
};

export interface BulkOrderDateIssue<TInput> extends BaseIssue<TInput> {
    readonly kind: 'validation';
    readonly type: 'bulk_order_date';
    readonly expected: null;
    readonly received: `"${string}"`;
    readonly requirement: (input: string | Date | unknown) => boolean;
}

export interface BulkOrderDateAction<
    TInput,
    TMessage extends ErrorMessage<BulkOrderDateIssue<TInput>> | undefined
> extends BaseValidation<TInput, TInput, BulkOrderDateIssue<TInput>> {
    readonly type: 'bulk_order_date';
    readonly reference: typeof validateBulkOrderDate;
    readonly expects: null;
    readonly requirement: (input: string | Date | unknown) => boolean;
    readonly message: TMessage;
}

export function validateBulkOrderDate<TInput>(
): BulkOrderDateAction<TInput, undefined>;

export function validateBulkOrderDate<
    TInput,
    const TMessage extends ErrorMessage<BulkOrderDateIssue<TInput>> | undefined,
>(
    message: TMessage
): BulkOrderDateAction<TInput, TMessage>;

export function validateBulkOrderDate(
    message?: ErrorMessage<BulkOrderDateIssue<unknown>>
): BulkOrderDateAction<unknown, ErrorMessage<BulkOrderDateIssue<unknown>> | undefined> {
    return {
        kind: 'validation',
        type: 'bulk_order_date',
        reference: validateBulkOrderDate,
        async: false,
        expects: null,
        requirement: (input: string | Date | unknown) => !dtIsPast(new Date(`${input}T23:59:00.000`)),
        message,
        // TODO: This is going to change to "~run"
        '~validate'(dataset, config) {
            if (dataset.typed && !this.requirement(dataset.value)) {
                v._addIssue(this, 'input', dataset, config);
            }
            
            return dataset;
        }
    };
}

export const bulkOrderDateModel = v.pipe(
    v.string('Date is required'),
    validateBulkOrderDate('Date must be today or in the future')
);

export const bulkOrderFormModel = v.object({
    // Manually validate when ready
    requestedShipDate: v.optional(
        v.nullish(bulkOrderDateModel)
    ),
    // Merge to validate
    shipRateUid: v.optional(
        v.nullish(
            v.string()
        )
    ),
    // Merge to validate
    backorderPreference: v.optional(
        v.nullish(
            v.string()
        )
    ),

    shippingService: v.optional(
        v.nullish(
            v.string()
        )
    ),
    shippingServicePreference: v.optional(
        v.nullish(
            v.string()
        )
    ),
    uid: v.optional(
        v.string()
    ),
    shippingAccount: v.nullish(
        v.string()
    ),
    purchaseOrderNo: v.nullish(
        v.string()
    ),
    paymentTerms: v.nullish(
        v.string()
    ),
    billToAddress: v.optional(
        v.nullish(
            v.string()
        )
    ),
    shipToAddress: v.string('Shipping Address is required'),
    isDropship: v.optional(
        v.boolean()
    ),
    customerNotes: v.nullish(
        v.string()
    )
});

export const bulkOrderFormModelFormStep2 = v.object({
    backorderPreference: v.string('Backorder Preference is required'),

    // Contact Fields
    contactEmail: v.pipe(
        v.string(fErr.email),
        v.email(fErr.email)
    ),
    contactFirstName: v.pipe(
        v.string(fErr.firstName),
        v.minLength(1, fErr.firstName)
    ),
    contactLastName: v.pipe(
        v.string(fErr.lastName),
        v.minLength(1, fErr.lastName)
    ),
    contactPhone: v.pipe(
        v.string(fErr.phone),
        v.minLength(1, fErr.phone)
    )
});

export const bulkOrderFormModelFormStepShipRateRequired = v.object({
    shipRateUid: v.string('Shipping Service is required')
});
export const bulkOrderFormModelFormStepShipRateOptional = v.object({
    shipRateUid: v.optional(
        v.string('Shipping Service is required')
    )
});

export type BulkOrderFormModel = InferOutput<typeof bulkOrderFormModel>;
