
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as _debug8nl3kq8PWAMeta } from "/usr/src/app/pages/_debug.vue?macro=true";
import { default as _91uid_93d2HDDUidksMeta } from "/usr/src/app/pages/accept-invitation/[uid].vue?macro=true";
import { default as addresses1USOpCZ7cbMeta } from "/usr/src/app/pages/account/addresses.vue?macro=true";
import { default as agreements7rl5MytZ2fMeta } from "/usr/src/app/pages/account/agreements.vue?macro=true";
import { default as balanceVLaw6fd0vwMeta } from "/usr/src/app/pages/account/balance.vue?macro=true";
import { default as bodieswOoXmyDcHrMeta } from "/usr/src/app/pages/account/bodies.vue?macro=true";
import { default as bulk_45orderbwcwZliVZnMeta } from "/usr/src/app/pages/account/bulk-order.vue?macro=true";
import { default as claimsnQs6mg6GkNMeta } from "/usr/src/app/pages/account/claims.vue?macro=true";
import { default as contactzFLI9pVwUmMeta } from "/usr/src/app/pages/account/contact.vue?macro=true";
import { default as faq1udRY4cKkxMeta } from "/usr/src/app/pages/account/faq.vue?macro=true";
import { default as index6LTnG94DlyMeta } from "/usr/src/app/pages/account/index.vue?macro=true";
import { default as invoicesmnt1zbWcKHMeta } from "/usr/src/app/pages/account/invoices.vue?macro=true";
import { default as manage_45membersA1iaMf7mzCMeta } from "/usr/src/app/pages/account/manage-members.vue?macro=true";
import { default as map_45policyCyupS1yqtbMeta } from "/usr/src/app/pages/account/map-policy.vue?macro=true";
import { default as marketing_45assetsDQto20KHRdMeta } from "/usr/src/app/pages/account/marketing-assets.vue?macro=true";
import { default as ordersVRYxFTVlPnMeta } from "/usr/src/app/pages/account/orders.vue?macro=true";
import { default as payment_45methodsQc2MB8ajkJMeta } from "/usr/src/app/pages/account/payment-methods.vue?macro=true";
import { default as pricing_45termswGXmAT21tbMeta } from "/usr/src/app/pages/account/pricing-terms.vue?macro=true";
import { default as product_45launchespXWvDzt9BjMeta } from "/usr/src/app/pages/account/product-launches.vue?macro=true";
import { default as product_45listZ4leRMPpOKMeta } from "/usr/src/app/pages/account/product-list.vue?macro=true";
import { default as profileaKN25GPWXpMeta } from "/usr/src/app/pages/account/profile.vue?macro=true";
import { default as registrationstHHceT9igqMeta } from "/usr/src/app/pages/account/registrations.vue?macro=true";
import { default as sku_45listhy5PIjNZngMeta } from "/usr/src/app/pages/account/sku-list.vue?macro=true";
import { default as subscriptionsltF9v8oe8HMeta } from "/usr/src/app/pages/account/subscriptions.vue?macro=true";
import { default as team3DzoL6rOSXMeta } from "/usr/src/app/pages/account/team.vue?macro=true";
import { default as third_45party_45marketplace_45policyWJcJV3a12DMeta } from "/usr/src/app/pages/account/third-party-marketplace-policy.vue?macro=true";
import { default as vouchersSWvHdCbE0TMeta } from "/usr/src/app/pages/account/vouchers.vue?macro=true";
import { default as accountdWl6ticfZzMeta } from "/usr/src/app/pages/account.vue?macro=true";
import { default as cart37DfDFSHEiMeta } from "/usr/src/app/pages/cart.vue?macro=true";
import { default as indexJPEr6nErnHMeta } from "/usr/src/app/pages/checkout/index.vue?macro=true";
import { default as checkoutShZmPbbKc4Meta } from "/usr/src/app/pages/checkout.vue?macro=true";
import { default as contactkLj0iaDgVSMeta } from "/usr/src/app/pages/contact.vue?macro=true";
import { default as contentqZd0yV980OMeta } from "/usr/src/app/pages/content.vue?macro=true";
import { default as indexie9eCXPhWiMeta } from "/usr/src/app/pages/create-account/index.vue?macro=true";
import { default as step_452kKrDJ21EYOMeta } from "/usr/src/app/pages/create-account/step-2.vue?macro=true";
import { default as create_45accountUPDxhDhF00Meta } from "/usr/src/app/pages/create-account.vue?macro=true";
import { default as faqDkOohHQxeIMeta } from "/usr/src/app/pages/faq.vue?macro=true";
import { default as forgot_45passwordP0Nxfp4jaIMeta } from "/usr/src/app/pages/forgot-password.vue?macro=true";
import { default as indexgw2ikJsyhcMeta } from "/usr/src/app/pages/index.vue?macro=true";
import { default as jobsBcOJ2M7dTWMeta } from "/usr/src/app/pages/jobs.vue?macro=true";
import { default as teamssOu1ASjxRnMeta } from "/usr/src/app/pages/onboarding/teams.vue?macro=true";
import { default as order_45statusFOgSfoUjPeMeta } from "/usr/src/app/pages/order-status.vue?macro=true";
import { default as confirmationna9gIKF8EwMeta } from "/usr/src/app/pages/order/confirmation.vue?macro=true";
import { default as privacyETeYDW5ioPMeta } from "/usr/src/app/pages/privacy.vue?macro=true";
import { default as list5zR8k7vO3AMeta } from "/usr/src/app/pages/product-catalog/list.vue?macro=true";
import { default as product_45catalogT7YjxaBTxnMeta } from "/usr/src/app/pages/product-catalog.vue?macro=true";
import { default as reset_45passwordZej1Ub4tM0Meta } from "/usr/src/app/pages/reset-password.vue?macro=true";
import { default as retail7aYuIju60lMeta } from "/usr/src/app/pages/retail.vue?macro=true";
import { default as returnsGlD88GNXYMMeta } from "/usr/src/app/pages/returns.vue?macro=true";
import { default as reviewsDLtKg5AaB6Meta } from "/usr/src/app/pages/reviews.vue?macro=true";
import { default as saleQo7QW6EbPjMeta } from "/usr/src/app/pages/sale.vue?macro=true";
import { default as _91txnUid_93ovj7s6Wtz9Meta } from "/usr/src/app/pages/sales/[txnUid].vue?macro=true";
import { default as searchHuFsYebDR6Meta } from "/usr/src/app/pages/search.vue?macro=true";
import { default as set_45passwordRRsWbKtFvTMeta } from "/usr/src/app/pages/set-password.vue?macro=true";
import { default as sign_45in7sEcJLcQCLMeta } from "/usr/src/app/pages/sign-in.vue?macro=true";
import { default as sign_45outoCG8V8nK4JMeta } from "/usr/src/app/pages/sign-out.vue?macro=true";
import { default as storesmmue6S2kQPMeta } from "/usr/src/app/pages/stores.vue?macro=true";
import { default as subscriptions5tUJgnoJ5wMeta } from "/usr/src/app/pages/subscriptions.vue?macro=true";
import { default as indexhJFIt1Ax6GMeta } from "/usr/src/app/pages/support/index.vue?macro=true";
import { default as warrantytHtDpiHJELMeta } from "/usr/src/app/pages/support/warranty.vue?macro=true";
import { default as termsZtn5bNC2S8Meta } from "/usr/src/app/pages/terms.vue?macro=true";
import { default as va_45productsNp2DsKp7r4Meta } from "/usr/src/app/pages/va-products.vue?macro=true";
import { default as component_45stub0e5PZ8YGKTMeta } from "/usr/src/app/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.4_db0@0.2.3_ioredis@5.4.2_magicast@_491da2ecb760a42cac4da5a0a8e92c50/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stub0e5PZ8YGKT } from "/usr/src/app/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.4_db0@0.2.3_ioredis@5.4.2_magicast@_491da2ecb760a42cac4da5a0a8e92c50/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "_debug",
    path: "/_debug",
    component: () => import("/usr/src/app/pages/_debug.vue")
  },
  {
    name: "accept-invitation-uid",
    path: "/accept-invitation/:uid()",
    meta: _91uid_93d2HDDUidksMeta || {},
    component: () => import("/usr/src/app/pages/accept-invitation/[uid].vue")
  },
  {
    name: accountdWl6ticfZzMeta?.name,
    path: "/account",
    meta: accountdWl6ticfZzMeta || {},
    component: () => import("/usr/src/app/pages/account.vue"),
    children: [
  {
    name: "account-addresses",
    path: "addresses",
    component: () => import("/usr/src/app/pages/account/addresses.vue")
  },
  {
    name: "account-agreements",
    path: "/account/agreements/:agreementUid?",
    meta: agreements7rl5MytZ2fMeta || {},
    alias: ["/account/agreements/:agreementUid?"],
    component: () => import("/usr/src/app/pages/account/agreements.vue")
  },
  {
    name: "account-balance",
    path: "balance",
    component: () => import("/usr/src/app/pages/account/balance.vue")
  },
  {
    name: "account-bodies",
    path: "bodies",
    component: () => import("/usr/src/app/pages/account/bodies.vue")
  },
  {
    name: "account-bulk-order",
    path: "bulk-order",
    meta: bulk_45orderbwcwZliVZnMeta || {},
    component: () => import("/usr/src/app/pages/account/bulk-order.vue")
  },
  {
    name: "account-claims",
    path: "claims",
    component: () => import("/usr/src/app/pages/account/claims.vue")
  },
  {
    name: "account-contact",
    path: "contact",
    component: () => import("/usr/src/app/pages/account/contact.vue")
  },
  {
    name: "account-faq",
    path: "faq",
    meta: faq1udRY4cKkxMeta || {},
    component: () => import("/usr/src/app/pages/account/faq.vue")
  },
  {
    name: "account",
    path: "",
    component: () => import("/usr/src/app/pages/account/index.vue")
  },
  {
    name: "account-invoices",
    path: "invoices",
    component: () => import("/usr/src/app/pages/account/invoices.vue")
  },
  {
    name: "account-manage-members",
    path: "manage-members",
    meta: manage_45membersA1iaMf7mzCMeta || {},
    component: () => import("/usr/src/app/pages/account/manage-members.vue")
  },
  {
    name: "account-map-policy",
    path: "map-policy",
    meta: map_45policyCyupS1yqtbMeta || {},
    component: () => import("/usr/src/app/pages/account/map-policy.vue")
  },
  {
    name: "account-marketing-assets",
    path: "marketing-assets",
    meta: marketing_45assetsDQto20KHRdMeta || {},
    component: () => import("/usr/src/app/pages/account/marketing-assets.vue")
  },
  {
    name: "account-orders",
    path: "orders",
    component: () => import("/usr/src/app/pages/account/orders.vue")
  },
  {
    name: "account-payment-methods",
    path: "payment-methods",
    component: () => import("/usr/src/app/pages/account/payment-methods.vue")
  },
  {
    name: "account-pricing-terms",
    path: "pricing-terms",
    meta: pricing_45termswGXmAT21tbMeta || {},
    component: () => import("/usr/src/app/pages/account/pricing-terms.vue")
  },
  {
    name: "account-product-launches",
    path: "product-launches",
    meta: product_45launchespXWvDzt9BjMeta || {},
    component: () => import("/usr/src/app/pages/account/product-launches.vue")
  },
  {
    name: "account-product-list",
    path: "product-list",
    meta: product_45listZ4leRMPpOKMeta || {},
    component: () => import("/usr/src/app/pages/account/product-list.vue")
  },
  {
    name: "account-profile",
    path: "profile",
    component: () => import("/usr/src/app/pages/account/profile.vue")
  },
  {
    name: "account-registrations",
    path: "registrations",
    component: () => import("/usr/src/app/pages/account/registrations.vue")
  },
  {
    name: "account-sku-list",
    path: "sku-list",
    meta: sku_45listhy5PIjNZngMeta || {},
    component: () => import("/usr/src/app/pages/account/sku-list.vue")
  },
  {
    name: "account-subscriptions",
    path: "subscriptions",
    component: () => import("/usr/src/app/pages/account/subscriptions.vue")
  },
  {
    name: "account-team",
    path: "team",
    meta: team3DzoL6rOSXMeta || {},
    component: () => import("/usr/src/app/pages/account/team.vue")
  },
  {
    name: "account-third-party-marketplace-policy",
    path: "third-party-marketplace-policy",
    meta: third_45party_45marketplace_45policyWJcJV3a12DMeta || {},
    component: () => import("/usr/src/app/pages/account/third-party-marketplace-policy.vue")
  },
  {
    name: "account-vouchers",
    path: "vouchers",
    component: () => import("/usr/src/app/pages/account/vouchers.vue")
  }
]
  },
  {
    name: "cart",
    path: "/cart",
    meta: cart37DfDFSHEiMeta || {},
    component: () => import("/usr/src/app/pages/cart.vue")
  },
  {
    name: checkoutShZmPbbKc4Meta?.name,
    path: "/checkout",
    meta: checkoutShZmPbbKc4Meta || {},
    component: () => import("/usr/src/app/pages/checkout.vue"),
    children: [
  {
    name: "checkout",
    path: "",
    component: () => import("/usr/src/app/pages/checkout/index.vue")
  }
]
  },
  {
    name: "contact",
    path: "/contact",
    meta: contactkLj0iaDgVSMeta || {},
    alias: ["/support/contact"],
    component: () => import("/usr/src/app/pages/contact.vue")
  },
  {
    name: "content",
    path: "/content/:catchAll(.*)",
    meta: contentqZd0yV980OMeta || {},
    alias: ["/:catchAll(.*)"],
    component: () => import("/usr/src/app/pages/content.vue")
  },
  {
    name: create_45accountUPDxhDhF00Meta?.name,
    path: "/create-account",
    meta: create_45accountUPDxhDhF00Meta || {},
    component: () => import("/usr/src/app/pages/create-account.vue"),
    children: [
  {
    name: "create-account",
    path: "",
    meta: indexie9eCXPhWiMeta || {},
    component: () => import("/usr/src/app/pages/create-account/index.vue")
  },
  {
    name: "create-account-step-2",
    path: "step-2",
    meta: step_452kKrDJ21EYOMeta || {},
    component: () => import("/usr/src/app/pages/create-account/step-2.vue")
  }
]
  },
  {
    name: "faq",
    path: "/faq",
    meta: faqDkOohHQxeIMeta || {},
    component: () => import("/usr/src/app/pages/faq.vue")
  },
  {
    name: "forgot-password",
    path: "/forgot-password",
    meta: forgot_45passwordP0Nxfp4jaIMeta || {},
    component: () => import("/usr/src/app/pages/forgot-password.vue")
  },
  {
    name: "index",
    path: "/",
    meta: indexgw2ikJsyhcMeta || {},
    component: () => import("/usr/src/app/pages/index.vue")
  },
  {
    name: "jobs",
    path: "/jobs",
    meta: jobsBcOJ2M7dTWMeta || {},
    alias: ["/company/jobs"],
    component: () => import("/usr/src/app/pages/jobs.vue")
  },
  {
    name: "onboarding-teams",
    path: "/onboarding/teams",
    meta: teamssOu1ASjxRnMeta || {},
    component: () => import("/usr/src/app/pages/onboarding/teams.vue")
  },
  {
    name: "order-status",
    path: "/order-status",
    meta: order_45statusFOgSfoUjPeMeta || {},
    component: () => import("/usr/src/app/pages/order-status.vue")
  },
  {
    name: "order-confirmation",
    path: "/order/confirmation/:order",
    meta: confirmationna9gIKF8EwMeta || {},
    alias: ["/order/confirmation/:order"],
    component: () => import("/usr/src/app/pages/order/confirmation.vue")
  },
  {
    name: "privacy",
    path: "/privacy",
    meta: privacyETeYDW5ioPMeta || {},
    component: () => import("/usr/src/app/pages/privacy.vue")
  },
  {
    name: "product-catalog",
    path: "/product-catalog",
    meta: product_45catalogT7YjxaBTxnMeta || {},
    component: () => import("/usr/src/app/pages/product-catalog.vue"),
    children: [
  {
    name: "product-catalog-list",
    path: "/product-catalog/:deeplink(.*)",
    meta: list5zR8k7vO3AMeta || {},
    alias: ["/product-catalog/:deeplink(.*)"],
    component: () => import("/usr/src/app/pages/product-catalog/list.vue")
  }
]
  },
  {
    name: "reset-password",
    path: "/reset-password",
    meta: reset_45passwordZej1Ub4tM0Meta || {},
    component: () => import("/usr/src/app/pages/reset-password.vue")
  },
  {
    name: "retail",
    path: "/retail",
    meta: retail7aYuIju60lMeta || {},
    component: () => import("/usr/src/app/pages/retail.vue")
  },
  {
    name: "returns",
    path: "/returns",
    meta: returnsGlD88GNXYMMeta || {},
    alias: ["/support/returns/"],
    component: () => import("/usr/src/app/pages/returns.vue")
  },
  {
    name: "reviews",
    path: "/reviews",
    meta: reviewsDLtKg5AaB6Meta || {},
    component: () => import("/usr/src/app/pages/reviews.vue")
  },
  {
    name: "sale",
    path: "/sale",
    component: () => import("/usr/src/app/pages/sale.vue")
  },
  {
    name: "sales-txnUid",
    path: "/sales/:txnUid",
    meta: _91txnUid_93ovj7s6Wtz9Meta || {},
    alias: ["/order/:txnUid","/invoice/:txnUid"],
    component: () => import("/usr/src/app/pages/sales/[txnUid].vue")
  },
  {
    name: "search",
    path: "/search",
    meta: searchHuFsYebDR6Meta || {},
    component: () => import("/usr/src/app/pages/search.vue")
  },
  {
    name: "set-password",
    path: "/set-password",
    meta: set_45passwordRRsWbKtFvTMeta || {},
    component: () => import("/usr/src/app/pages/set-password.vue")
  },
  {
    name: "sign-in",
    path: "/sign-in",
    meta: sign_45in7sEcJLcQCLMeta || {},
    component: () => import("/usr/src/app/pages/sign-in.vue")
  },
  {
    name: "sign-out",
    path: "/sign-out",
    meta: sign_45outoCG8V8nK4JMeta || {},
    component: () => import("/usr/src/app/pages/sign-out.vue")
  },
  {
    name: "stores",
    path: "/stores",
    meta: storesmmue6S2kQPMeta || {},
    alias: ["/retailers/stores"],
    component: () => import("/usr/src/app/pages/stores.vue")
  },
  {
    name: "subscriptions",
    path: "/subscriptions",
    meta: subscriptions5tUJgnoJ5wMeta || {},
    component: () => import("/usr/src/app/pages/subscriptions.vue")
  },
  {
    name: "support",
    path: "/support",
    meta: indexhJFIt1Ax6GMeta || {},
    component: () => import("/usr/src/app/pages/support/index.vue")
  },
  {
    name: "support-warranty",
    path: "/support/warranty",
    meta: warrantytHtDpiHJELMeta || {},
    alias: ["/support/warranty/submit-claim"],
    component: () => import("/usr/src/app/pages/support/warranty.vue")
  },
  {
    name: "terms",
    path: "/terms",
    meta: termsZtn5bNC2S8Meta || {},
    component: () => import("/usr/src/app/pages/terms.vue")
  },
  {
    name: "va-products",
    path: "/va-products",
    component: () => import("/usr/src/app/pages/va-products.vue")
  },
  {
    name: component_45stub0e5PZ8YGKTMeta?.name,
    path: "/about-us",
    component: component_45stub0e5PZ8YGKT
  },
  {
    name: component_45stub0e5PZ8YGKTMeta?.name,
    path: "/account/manage-team",
    component: component_45stub0e5PZ8YGKT
  },
  {
    name: component_45stub0e5PZ8YGKTMeta?.name,
    path: "/warranty",
    component: component_45stub0e5PZ8YGKT
  },
  {
    name: component_45stub0e5PZ8YGKTMeta?.name,
    path: "/redeem",
    component: component_45stub0e5PZ8YGKT
  },
  {
    name: component_45stub0e5PZ8YGKTMeta?.name,
    path: "/register",
    component: component_45stub0e5PZ8YGKT
  },
  {
    name: component_45stub0e5PZ8YGKTMeta?.name,
    path: "/support/register",
    component: component_45stub0e5PZ8YGKT
  },
  {
    name: component_45stub0e5PZ8YGKTMeta?.name,
    path: "/apparel",
    component: component_45stub0e5PZ8YGKT
  },
  {
    name: component_45stub0e5PZ8YGKTMeta?.name,
    path: "/apparel/shirts",
    component: component_45stub0e5PZ8YGKT
  },
  {
    name: component_45stub0e5PZ8YGKTMeta?.name,
    path: "/apparel/outerwear",
    component: component_45stub0e5PZ8YGKT
  },
  {
    name: component_45stub0e5PZ8YGKTMeta?.name,
    path: "/apparel/socks",
    component: component_45stub0e5PZ8YGKT
  },
  {
    name: component_45stub0e5PZ8YGKTMeta?.name,
    path: "/va-products/level-4-sterile-surgical-gowns/:pathMatch(.*)",
    component: component_45stub0e5PZ8YGKT
  },
  {
    name: component_45stub0e5PZ8YGKTMeta?.name,
    path: "/va-products/level-3-sterile-surgical-gowns/:pathMatch(.*)",
    component: component_45stub0e5PZ8YGKT
  },
  {
    name: component_45stub0e5PZ8YGKTMeta?.name,
    path: "/va-products/level-2-isolation-gowns/:pathMatch(.*)",
    component: component_45stub0e5PZ8YGKT
  },
  {
    name: component_45stub0e5PZ8YGKTMeta?.name,
    path: "/va-products/level-3-surgical-mask/:pathMatch(.*)",
    component: component_45stub0e5PZ8YGKT
  },
  {
    name: component_45stub0e5PZ8YGKTMeta?.name,
    path: "/va-products/over-the-head-protective-gowns/:pathMatch(.*)",
    component: component_45stub0e5PZ8YGKT
  },
  {
    name: component_45stub0e5PZ8YGKTMeta?.name,
    path: "/symbols",
    component: component_45stub0e5PZ8YGKT
  }
]