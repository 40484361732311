import { toPlainObject as lo_toPlainObject } from 'es-toolkit/compat';
import { merge as lo_mergeCompat } from 'es-toolkit/compat';

export class BaseModel<T> {
    id: number | undefined = undefined;
    uid: string | undefined = undefined;

    static new(): InstanceType<typeof this> {
        return new this();
    }

    static getBaseProps() {
        return lo_toPlainObject(new BaseModel());
    }

    static toPlainObject<TT = any>(obj?: any): TT {
        const inst = new this();
        lo_mergeCompat(inst, obj);
        return lo_toPlainObject(inst) as TT;
    }

    toPlainObject(): T {
        return lo_toPlainObject(this) as T;
    }
}
