import type {
    Address,
    CustomPagination,
    PaginatedRequest,
    PaginatedPayload,
    UpdateApiDataParams,
    BaseCrudApiDataModel,
    CartContactInfo
} from '~/types';
import { AddressModel } from '~/models';
import { useApiUtils, useCustomFetch, useGenericCrudMethods } from '~/composables';
import { omit as lo_omit } from 'es-toolkit';
import { isError as lo_isError } from 'es-toolkit';
import type { NitroFetchOptions } from 'nitropack';

export const UnsavedAddresses = () => useState<AddressModel[]>('keyUnsavedAddresses', () => []);

export function useAddresses() {
    const apiBasePath = '/v1/addresses';
    const useApiUtilsObj = useApiUtils();
    const $_fetch = useCustomFetch();
    const unsavedAddresses = UnsavedAddresses();
    const genericCrudMethods = useGenericCrudMethods<Address>(apiBasePath, AddressModel);

    async function getItems(args: PaginatedRequest): Promise<PaginatedPayload<AddressModel> | Error> {
        const response = await getAddresses(args);

        if (lo_isError(response)) {
            return response;
        }

        const data = response.data.map<AddressModel>((obj) => AddressModel.toPlainObject<AddressModel>(obj));

        return {
            pagination: response.pagination,
            data
        };
    }

    async function getAddresses(args: PaginatedRequest): Promise<PaginatedPayload<Address> | Error> {
        const url = args?.url ?? `${apiBasePath}/`;
        const config: NitroFetchOptions<string> = {
            method: 'GET'
        };

        if (args?.params) {
            config.query = args?.params;
        }

        let paginatedPayload: PaginatedPayload<Address>;

        try {
            const response = await $_fetch<CustomPagination<Address>>(url, config);
    
            paginatedPayload = {
                pagination: lo_omit(response, ['results']),
                data: response.results
            };

            return paginatedPayload;
        } catch (err) {
            return useApiUtilsObj.getErrorObj(err);
        }
    }

    async function upsertItem(data: Partial<Address>, bypassValidation: boolean = false): Promise<Address | Error> {
        let url = `${apiBasePath}/`;
        const uid = data.uid;
        const config: NitroFetchOptions<string> = {
            body: data,
            method: 'POST',
        };

        if (uid) {
            config.method = 'PATCH';
            url = `${url}${uid}/`;

            if (bypassValidation) {
                url = `${url}bypass-validation/`;
            }
        }

        try {
            const response = await $_fetch<Address>(url, config);
            return AddressModel.toPlainObject<Address>(response);
        } catch (err) {
            return useApiUtilsObj.getErrorObj(err);
        }
    }

    function cartContactInfoToAddress(cartInfoObj: CartContactInfo): Pick<Address, 'firstName' | 'lastName' | 'email' | 'phone'> {
        const {
            contactFirstName,
            contactLastName,
            contactEmail,
            contactPhone
        } = cartInfoObj;
        
        return {
            firstName: contactFirstName,
            lastName: contactLastName,
            email: contactEmail,
            phone: contactPhone
        };
    }

    return {
        ...genericCrudMethods,
        getItems,
        getAddresses,
        upsertItem,
        unsavedAddresses,
        cartContactInfoToAddress
    };
}
