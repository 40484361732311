import revive_payload_client_pJbTHY5szC from "/usr/src/app/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.4_db0@0.2.3_ioredis@5.4.2_magicast@_491da2ecb760a42cac4da5a0a8e92c50/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_Pw5ShvdEVS from "/usr/src/app/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.4_db0@0.2.3_ioredis@5.4.2_magicast@_491da2ecb760a42cac4da5a0a8e92c50/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_lbdJv2L00v from "/usr/src/app/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.4_db0@0.2.3_ioredis@5.4.2_magicast@_491da2ecb760a42cac4da5a0a8e92c50/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_NVZh1T3TWz from "/usr/src/app/node_modules/.pnpm/nuxt-site-config@3.1.0_magicast@0.3.5_rollup@4.32.1_vue@3.5.13_typescript@5.7.3_/node_modules/nuxt-site-config/dist/runtime/app/plugins/0.siteConfig.js";
import payload_client_0jZBovCfrZ from "/usr/src/app/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.4_db0@0.2.3_ioredis@5.4.2_magicast@_491da2ecb760a42cac4da5a0a8e92c50/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_cDgaxNeg3g from "/usr/src/app/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.4_db0@0.2.3_ioredis@5.4.2_magicast@_491da2ecb760a42cac4da5a0a8e92c50/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_3Q2rs7Gn0r from "/usr/src/app/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.4_db0@0.2.3_ioredis@5.4.2_magicast@_491da2ecb760a42cac4da5a0a8e92c50/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import restore_state_client_ZC5uRhT1ui from "/usr/src/app/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.4_db0@0.2.3_ioredis@5.4.2_magicast@_491da2ecb760a42cac4da5a0a8e92c50/node_modules/nuxt/dist/app/plugins/restore-state.client.js";
import chunk_reload_client_tmXJmB4UPL from "/usr/src/app/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.4_db0@0.2.3_ioredis@5.4.2_magicast@_491da2ecb760a42cac4da5a0a8e92c50/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/usr/src/app/.nuxt/components.plugin.mjs";
import prefetch_client_q8wpvGySA0 from "/usr/src/app/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.4_db0@0.2.3_ioredis@5.4.2_magicast@_491da2ecb760a42cac4da5a0a8e92c50/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import _00_init_client_77O4tQBcSo from "/usr/src/app/plugins/00.init.client.ts";
import _01_auth_client_UOBMAirzzr from "/usr/src/app/plugins/01.auth.client.ts";
import _03_vuetify_hLVW487wAp from "/usr/src/app/plugins/03.vuetify.ts";
export default [
  revive_payload_client_pJbTHY5szC,
  unhead_Pw5ShvdEVS,
  router_lbdJv2L00v,
  _0_siteConfig_NVZh1T3TWz,
  payload_client_0jZBovCfrZ,
  navigation_repaint_client_cDgaxNeg3g,
  check_outdated_build_client_3Q2rs7Gn0r,
  restore_state_client_ZC5uRhT1ui,
  chunk_reload_client_tmXJmB4UPL,
  components_plugin_KR1HBZs4kY,
  prefetch_client_q8wpvGySA0,
  _00_init_client_77O4tQBcSo,
  _01_auth_client_UOBMAirzzr,
  _03_vuetify_hLVW487wAp
]